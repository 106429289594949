import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { SocketContext, socket } from "./context/socket";
import GuessWho from "./components/guesswho";
import Marathon2023 from "./components/marathon2023";
import ChallengeLaunch from "./components/challengelaunch";
import GuessTheTheme from "./components/guessthetheme";
import Welcome from "./components/welcome";
import Questions from "./components/questions";
import WritingWorkshop from "./components/writingworkshop";


function App() {
  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <div className="bg">
          <Router>
            <Switch>
              <Route path="/" exact component={() => <Welcome />} />
              <Route path="/guesswho" exact component={() => <GuessWho />} />
              <Route
                path="/marathon-2023"
                exact
                component={() => <Marathon2023 />}
              />
              <Route
                path="/competition"
                exact
                component={() => <ChallengeLaunch />}
              />
              <Route
                path="/guessthetheme"
                exact
                component={() => <GuessTheTheme />}
             />
              <Route
                path="/questions"
                exact
                component={() => <Questions />}
             />
              <Route
                path="/writingworkshop"
                exact
                component={() => <WritingWorkshop />}
             />
              <Redirect to="/" />
            </Switch>
          </Router>
        </div>
      </div>
    </SocketContext.Provider>
  );
}

export default App;

import {
  MDBBox,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import "../index.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { SocketContext } from "../context/socket";
import { Socket } from "socket.io-client";

type GuessWhoEntry = {
  name1: string;
  name2: string;
  name3: string;
  participantName: string;
  participantEmail: string;
};

type GuessWhoSetup = {
  anagram: string;
  imageUrl: string;
};

export default function GuessWho() {
  useEffect(() => {
    document.title = 'Guess Who';
  }, []);

  const [name1, setName1] = useState<string>("");
  const [name2, setName2] = useState<string>("");
  const [name3, setName3] = useState<string>("");
  const [participantName, setParticipantName] = useState<string>("");
  const [participantEmail, setParticipantEmail] = useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [agree, setAgreement] = useState<boolean>(false);
  const [hasBeenSubmitted, setSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [setupData, setSetupData] = useState<GuessWhoSetup[]>([]);

  const socket: Socket = React.useContext(SocketContext);

  useEffect(() => {
    axios.get(`https://${window.location.hostname}/strapi/setupguesswhos`)
      .then(response => {
        const data = response.data.map((item: any) => ({
          anagram: item.anagram,
          imageUrl: item.image.url
        }));
        setSetupData(data);
      })
      .catch(err => {
        console.error("API fetch error: ", err);
      });
  }, []);

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailErrorMessage("Invalid email format");
    } else {
      setEmailErrorMessage("");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateEmail(participantEmail);
    if (emailErrorMessage) {
      return;
    }

    const data: GuessWhoEntry = {
      name1: name1,
      name2: name2,
      name3: name3,
      participantName: participantName,
      participantEmail: participantEmail,
    };

    axios
      .post(`https://${window.location.hostname}/strapi/guesswho`, data)
      .then((res) => {
        socket.emit("newEntry", "");
        setSubmit(true);
      })
      .catch((err) => {
        setErrorMessage("Sorry, something went wrong. Please try again later.");
      });
  };

  return (
    <div className="bg">
      <MDBContainer>
        <MDBBox>
          <br />
          <br />
          <br />
        </MDBBox>
        <MDBRow center>
          <MDBCol md="6">
            {!hasBeenSubmitted ? (
              <form onSubmit={handleSubmit}>
                <p
                  className="h2 text-center mb-4 white-text futuraFuture"
                  style={{ marginTop: "-1em", marginBottom: "-1em" }}
                >
                  Guess the name of the people in the anagrams
                </p>
                <div className="image-container" style={{ textAlign: "center" }}>
                  {setupData.map((entry, index) => (
                    <React.Fragment key={index}>
                      <img
                        src={"/strapi" + entry.imageUrl}
                        alt={`Face${index + 1}`}
                        height={500}
                        width={500}
                        style={{ marginTop: "2em" }}
                        className="responsive-image"
                      />
                      <br />
                      <label
                        htmlFor={`nameInput${index + 1}`}
                        className="white-text"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {entry.anagram}
                      </label>
                      <br />
                      <label
                        htmlFor={`nameInput${index + 1}`}
                        className="white-text"
                      >
                        Name {index + 1}:
                      </label>
                      <input
                        required={true}
                        type="text"
                        id={`nameInput${index + 1}`}
                        className="form-control"
                        value={[name1, name2, name3][index]}
                        onChange={(e) => {
                          const updatedNames = [name1, name2, name3];
                          updatedNames[index] = e.target.value;
                          setName1(updatedNames[0]);
                          setName2(updatedNames[1]);
                          setName3(updatedNames[2]);
                        }}
                        style={{ marginBottom: "2em" }}
                      />
                    </React.Fragment>
                  ))}
                  <p className="h5 text-center mb-4 white-text futuraFuture">
                    Personal information
                  </p>
                  <label htmlFor="participantName" className="white-text">
                    Your name:
                  </label>
                  <input
                    required={true}
                    type="text"
                    id="participantName"
                    className="form-control"
                    value={participantName}
                    onChange={(e) => setParticipantName(e.target.value)}
                    style={{ marginBottom: "2em" }}
                    autoComplete="name"
                  />
                  <label htmlFor="participantEmail" className="white-text">
                    Your email:
                  </label>
                  <input
                    required={true}
                    type="text"
                    id="participantEmail"
                    className={`form-control ${emailErrorMessage ? "is-invalid" : ""}`}
                    value={participantEmail}
                    onChange={(e) => {
                      setParticipantEmail(e.target.value);
                    }}
                    onBlur={(e) => validateEmail(e.target.value)}
                    autoComplete="email"
                  />
                  {emailErrorMessage && (
                    <div className="invalid-feedback">{emailErrorMessage}</div>
                  )}
                  <br />
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultUnchecked"
                      value="accept"
                      onChange={(e) => setAgreement(!agree)}
                    />
                    <label className="custom-control-label" htmlFor="defaultUnchecked" style={{ color: "white" }}>
                      By entering this competition, I accept that Aarhus Symposium may send information regarding events and
                      important deadlines and I accept the{" "}
                      <a href="https://aarhus-symposium.org/terms">
                        Privacy Policy & Terms of Service
                      </a>
                    </label>
                  </div>
                  {errorMessage && (
                    <p className="text-center mt-4 futuraFont" style={{ color: "red" }}>
                      {errorMessage}
                    </p>
                  )}
                  <div className="text-center mt-4 futuraFont">
                    <MDBBtn color="light" outline type="submit" disabled={!agree}>
                      Send
                      <MDBIcon far icon="paper-plane" className="ml-2" />
                    </MDBBtn>
                  </div>
                  <br />
                </div>
              </form>
            ) : (
              <p className="h1 text-center mb-4 white-text futuraFont">
                SUCCESS
              </p>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
import {
  MDBBox,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import "../index.css";
import React, { useEffect } from 'react';
import axios from "axios";
import { SocketContext } from "../context/socket";
import { Socket } from "socket.io-client";

type ChallengeLaunchEntry = {
  name: string;
  email: string;
};

export default function ChallengeLaunch() {
  useEffect(() => {
    document.title = 'Competition';
  }, []);
  const [name, setName] = React.useState<string>("");
  const [email, setParticipantEmail] = React.useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
  const [agree, setAgreement] = React.useState<boolean>(false);
  const [hasBeenSubmitted, setSubmit] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const socket: Socket = React.useContext(SocketContext);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailErrorMessage("Invalid email format");
    } else {
      setEmailErrorMessage("");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    validateEmail(email);
    if (emailErrorMessage) {
      return;
    }

    const data: ChallengeLaunchEntry = {
      name: name,
      email: email,
    };

    axios
      .post(`https://${window.location.hostname}/strapi/challengelaunch`, data)
      .then((res) => {
        socket.emit("newEntry", "");
        setSubmit(true);
      })
      .catch((err) => {
        setErrorMessage("Sorry, something went wrong. Please try again later.");
      });
  };

  return (
    <div className="bg">
      <MDBContainer>
        <MDBBox>
          <br />
          <br />
          <br />
        </MDBBox>
        <MDBRow center>
          <MDBCol md="6">
            {!hasBeenSubmitted ? (
              <form onSubmit={handleSubmit}>
                <p
                  className="h1 text-center mb-4 white-text futuraFuture"
                  style={{ paddingBottom: "2rem" }}
                >
                  Aarhus Symposium
                </p>
                <p
                  className="h4 text-center mb-4 white-text futuraFuture"
                  style={{ marginTop: "-1em", marginBottom: "-1em" }}
                >
                  Please fill in your personal information below to participate
                </p>
                <br></br>
                <label
                  htmlFor="defaultFormContactEmailEx"
                  className="white-text"
                >
                  Your name:
                </label>

                <input
                  required={true}
                  type="text"
                  id="defaultFormContactNameEx"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ marginBottom: "2em" }}
                  autoComplete="name"
                ></input>

                <label
                  htmlFor="defaultFormContactEmailEx"
                  className="white-text"
                >
                  Your email:
                </label>

                <input
                  required={true}
                  type="text"
                  id="defaultFormContactNameEx"
                  className={`form-control ${
                    emailErrorMessage ? "is-invalid" : ""
                  }`}
                  value={email}
                  onChange={(e) => {
                    setParticipantEmail(e.target.value);
                  }}
                  onBlur={(e) => validateEmail(e.target.value)}
                  autoComplete="email"
                />
                {emailErrorMessage && (
                  <div className="invalid-feedback">{emailErrorMessage}</div>
                )}
                <br />

                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked"
                    value="accept"
                    onChange={(e) => {
                      setAgreement(agree ? false : true);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked"
                    style={{ color: "white" }}
                  >
                    By entering this competition, I accept that Aarhus Symposium
                    may send information regarding events and important
                    deadlines, and I accept the{" "}
                    <a href="https://aarhus-symposium.org/terms">
                      Privacy Policy & Terms of Service
                    </a>
                  </label>
                </div>

                {errorMessage && (
                  <p
                    className="text-center mt-4 futuraFont"
                    style={{ color: "red" }}
                  >
                    {errorMessage}
                  </p>
                )}

                <div className="text-center mt-4 futuraFont">
                  <MDBBtn color="light" outline type="submit" disabled={!agree}>
                    Send
                    <MDBIcon far icon="paper-plane" className="ml-2" />
                  </MDBBtn>
                </div>
                <br />
              </form>
            ) : (
              <p className="h1 text-center mb-4 white-text futuraFont">
                SUCCESS
              </p>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
